@font-face {
  font-family: 'Loft';
  font-weight: 200;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Th.woff2') format('woff2');
}

@font-face {
  font-family: 'Loft';
  font-weight: 300;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Lt.woff2') format('woff2');
}

@font-face {
  font-family: 'Loft';
  font-weight: 400;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Rg.woff2') format('woff2');
}

@font-face {
  font-family: 'Loft';
  font-weight: 500;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Md.woff2') format('woff2');
}

@font-face {
  font-family: 'Loft';
  font-weight: 600;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Sb.woff2') format('woff2');
}
@font-face {
  font-family: 'Loft';
  font-weight: 700;
  font-style: normal;

  font-display: fallback; /* <- this can be added to each @font-face definition */
  src: url('/fonts/Loft/IcelandairLoft-W-Bd.woff2') format('woff2');
}
