.anchor-js-class {
  transition: opacity 0.25s ease;
  color: #435064 !important;
}

.anchor-js-class:hover,
.anchor-js-class:focus {
  opacity: 1;
  text-decoration: none !important;
  outline: 0;
}

@media (max-width: 768px) {
  .anchor-js-class {
    margin-left: 0 !important;
    position: relative !important;
    opacity: 0.5;
  }
}
